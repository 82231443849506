<div class="gdlr-core-pbf-wrapper" id="gdlr-core-wrapper-2">
  <div class="gdlr-core-pbf-background-wrap">
    <div class="gdlr-core-pbf-background gdlr-core-js" id="div_1dd7_13"></div>
  </div>
  <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
    <div class="gdlr-core-pbf-wrapper-container clearfix">
      <div
        class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
      >
        <div
          class="gdlr-core-pbf-column-content-margin gdlr-core-js"
          id="div_1dd7_14"
          style="min-height: 495px; max-height: 495px"
        >
          <div class="gdlr-core-pbf-background-wrap">
            <div class="gdlr-core-pbf-background gdlr-core-js" id="div_1dd7_15">
              <div style="text-align: center">
                <h3
                  class="gdlr-core-title-item-title gdlr-core-skin-title"
                  style="padding-top: 10px; text-shadow: 4px 4px #0000"
                  id="h3_1dd7_4"
                >
                  Today @SRNMC
                </h3>
              </div>
              <div style="width: 100%; margin-bottom: 10px">
                <div
                  style="
                    width: 60%;
                    margin: auto;
                    background: #3db166;
                    border-radius: 5px;
                    padding-top: 5px;
                  "
                  *ngIf="dayOrder && dayOrder.dayOrder !== ''"
                >
                  <h3
                    id="a_1dd7_0"
                    style="font-size: 18px"
                    *ngIf="dayOrder.dayOrder !== 'HOLIDAY'"
                  >
                    {{ dayOrder.dayOrder }}
                  </h3>
                  <h3
                    id="a_1dd7_0"
                    style="font-size: 18px"
                    *ngIf="dayOrder.dayOrder === 'HOLIDAY'"
                  >
                    Holiday : {{ dayOrder.dayOrderLeaveReason }}
                  </h3>
                </div>
                <div
                  style="
                    width: 60%;
                    margin: auto;
                    padding-top: 5px;
                    padding-bottom: 10px;
                  "
                >
                  <angular-weather-widget
                    APIKEY="ddebc8aada699affee140b9541fab539"
                    [width]="'auto'"
                    [height]="'auto'"
                    [backgroundColor]="'#192f59'"
                    [isBoxShadow]="true"
                    [borderRadius]="'5px'"
                    [locationFontSize]="'35px'"
                    [locationFontColor]="'#fff'"
                    [status]="true"
                    [statusFontColor]="'#fff'"
                    [statusFontSize]="'18px'"
                    [temperature]="true"
                    [tempratureFontColor]="'#fff'"
                    [tempratureFontSize]="'75px'"
                    [weatherImages]="true"
                    [weatherImageWidth]="'110px'"
                    [weatherImageHeight]="'125px'"
                    [location]="'Sattur'"
                    [isWind]="true"
                  >
                  </angular-weather-widget>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js"></div>
        </div>
      </div>
      <div
        class="gdlr-core-pbf-column gdlr-core-column-30"
        id="gdlr-core-column-4"
      >
        <div
          class="gdlr-core-pbf-column-content-margin gdlr-core-js"
          id="div_1dd7_16"
          style="min-height: 495px; max-height: 495px"
        >
          <div class="gdlr-core-pbf-background-wrap">
            <div
              class="gdlr-core-pbf-background gdlr-core-js"
              id="div_1dd7_17"
            ></div>
          </div>
          <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                id="div_1dd7_18"
              >
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <!-- <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_4">Important Links</h3> -->
                  <br />
                </div>
                <!-- <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                  id="span_1dd7_0">2020-21 applications are now open</span> -->
              </div>
            </div>
            <!-- <div class="brochure">
              <a
                class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                target="_blank"
                href="https://drive.google.com/file/d/1nPFKSkp2aUf43R-yz9CjPG1kqxqPGtCb/view?usp=share_link"
                id="a_1dd7_0"
              >
                <span class="gdlr-core-content">
                  Application Brochure for ALL programmes (2023 -2024 )
                </span>
              </a>
              <br />
              <h4>Department wise Brochure (2023 -2024)</h4>
              <div class="brochure-buttons">
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1J6iY_uzy4LJ4nIjeKWoJot81ZB_GlwMw/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Tamil </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1s2T2snmkK1s2_3wWh4886CPmYnUYRxlP/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> English </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1Y0kNctUFYV5bb88FqllZ1AaVQiHaziR8/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> History </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1y861iDnH6byFrGltoNsvcd7fzUH-oOiW/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Commerce </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1hSNBDSRXM0gnp9LdoP_cxTxnda3JmprX/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Chemistry </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1hjMYwbgscMMzixAGQGxz9u8uEYQs3Zzi/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Physics </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1eqwB69bmTAf9fyr9Gm-truvP4zFI8Zdr/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Mathematics </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1lVrsDhV8hHV4jP-QeBZfCse4dmxQNmzF/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Zoology </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1buqVIbfHIeQdSuDl6Jas40pNr37Ws5Tk/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content"> Computer Science </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1ulYp8SDD0wRKSlZbh-itutvOE9M0xKWR/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content">
                    Nutrition and Dietetics
                  </span>
                </a>
                <a
                  class="gdlr-core-button gdlr-core-button-solid gdlr-core-button-no-border"
                  target="_blank"
                  href="https://drive.google.com/file/d/1w3qj4hnCTUaEqlPFcAB2gSHUT1uyjKnH/view?usp=share_link"
                  id="a_1dd7_0"
                >
                  <span class="gdlr-core-content">
                    Business Administration
                  </span>
                </a>
              </div>
            </div> -->
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" target="_blank"
                  href="https://events.srnmcollege.ac.in"
                  id="a_1dd7_0">
                  <span class="gdlr-core-content">EVENTS @ SRNMC</span> </a>
              </div>

            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" target="_blank"
                  href="https://formbuilder.ccavenue.com/live/tamilnad-mercantile-bank/s-ramasamy-naidu-college/aided"
                  id="a_1dd7_0">
                  <span class="gdlr-core-content">ONLINE PAYMENT LINK - AIDED</span> </a>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" target="_blank"
                  href="https://formbuilder.ccavenue.com/live/tamilnad-mercantile-bank/s-ramasamy-naidu-college/self-finance"
                  id="a_1dd7_0">
                  <span class="gdlr-core-content">ONLINE PAYMENT LINK - SELF FINANCE</span> </a>
              </div>
            </div>
            <!-- <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" target="_blank"
                  href="https://formbuilder.ccavenue.com/live/tamilnad-mercantile-bank/s-ramasamy-naidu-college/exam-fee-7"
                  id="a_1dd7_0">
                  <span class="gdlr-core-content">Examination Fee - ONLINE Payment</span> </a>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" target="_blank"
                  href="https://admission.srnmcollege.ac.in/"
                  id="a_1dd7_0">
                  <span class="gdlr-core-content">Admission 2024-2025</span> </a>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
                  href="https://srnmcollege.ac.in/result_srnm.php" target="_blank" id="a_1dd7_0">
                  <span class="gdlr-core-content">END SEMESTER EXAMINATION RESULT APRIL & NOVEMBER 2020 </span> </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
